import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';

import SOM from './SOM';

import instructorImage from './img/instructors.png';
import childrenImage from './img/Childrens-Program-1024x473.jpg';
import groupImage from './img/group.jpg';
import teaImage from './img/Tea-Ceremony.jpg';
import somImage from './img/som/som.jpg';
import scheduleImage from './img/schedule/schedule.png';

class Home extends Component {
  render() {
    return (
      <div id="homePage">
        <div className="box">
          <div className="row">
            <div className="col-12 text-center">

              <Carousel style={{ width: "100%" }}>
                <Carousel.Item>
                  <img alt="instructors" src={instructorImage} style={{ width: "100%" }} />
                  <Carousel.Caption>
                    <h3 style={{ display: 'none' }}>Our Instructors</h3>
                    <p></p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img alt="children program" src={childrenImage} style={{ width: "100%" }} />
                  <Carousel.Caption>
                    <h3 style={{ display: 'none' }}>Children Program</h3>
                    <p></p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img alt="adult program" src={groupImage} style={{ width: "100%" }} />
                  <Carousel.Caption>
                    <h3 style={{ display: 'none' }}>Adult Program</h3>
                    <p></p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img alt="tea ceremony" src={teaImage} style={{ width: "100%" }} />
                  <Carousel.Caption>
                    <h3 style={{ display: 'none' }}>Black Belt Club</h3>
                    <p></p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>

              <h2 className="brand-before">
                <small>Welcome to</small>
              </h2>
              <h1 className="brand-name">Kian Taekwondo</h1>
              <hr className="tagline-divider" />
              <p className="tagline">A Premiere Taekwondo Martial Arts School in Southern New Hampshire</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <div className="box">
              <div className="row">
                <div className="col-12">
                  <iframe title="Kian Taekwondo on Facebook!" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FKianTaekwondo%2F&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1074217852721755" width="500" height="500" style={{ border: 'none', overflow: 'hidden', width: "100%" }} scrolling="no" frameBorder="0" ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <SOM image={somImage} studentName="Abi" />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="box" style={{ textAlign: 'center' }}>
              <hr />
              <h2 className="intro-text text-center">
                <strong>Current Schedule</strong>
              </h2>
              <hr />
              <p style={{ textAlign: 'center', marginTop: 40 }}>
                <a href="/schedule.pdf" target="_new">
                  <img src={scheduleImage} alt="schedule" style={{ maxWidth: '100%' }} />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;