import React, { Component } from 'react';
import Programs from "./Programs";

class About extends Component {

  render() {
    return (
      <div>
        <div className="row">
          <div className="box">
            <div className="col-12">
              <hr />
              <h2 className="intro-text text-center">
                <strong>About Kian Taekwondo</strong>
              </h2>
              <hr />
              <p>Kian Taekwondo offers world-class programs for students of all ages taught by Master Wonil Seo, a Kukkiwon certified 7th Degree Master born, raised,
              and trained in Korea. Programs include traditional Taekwondo training, weapons traning, and Olympic sparring. All students are taught valuable life lessons of
              respect, humility, perseverance, self-control, and honesty from day one. However, training does not stop at the mats as they are required to take these life
                  lessons to their homes, classrooms, and workplaces.</p>

              <p>Contact us for more details on any of our programs or to schedule a free consultation!</p>
            </div>
          </div>
        </div>
        <Programs />
      </div>
    );
  }
}

export default About;